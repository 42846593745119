<template>
  <b-card>
    <h5
      v-if="surveys.length !== 0"
      class="mb-2"
    >
      {{ $t('Survey') }}
    </h5>

    <div>
      <div
        v-if="surveys.length === 0"
        class="text-center text-muted"
      >
        <div class="meetup-day">
          <feather-icon
            icon="TwitchIcon"
            size="34"
            class="mr-50 text-primary"
          />{{ $t('MESSAGE.NO_SURVEY_AVAILABLE') }}
        </div>
      </div>
      <template v-else>
        <div
          v-for="(data, index) in surveys"
          :key="index"
          class="d-flex justify-content-start align-items-center mt-1"
        >
          <div class="mr-1">
            <feather-icon
              icon="TwitchIcon"
              size="24"
              class="mr-50 text-primary"
            />
          </div>
          <div class="profile-user-info">
            <h6 class="mb-0">
              {{ strSubstring(data.name, 30) }}
            </h6>
            <small class="text-muted">{{ formatDate(data.start) }}</small>
          </div>
          <div class="profile-star ml-auto">
            <feather-icon
              icon="EyeIcon"
              size="18"
              class="text-muted cursor-pointer"
              @click="$router.push({ name: 'survey-question-id', params: { id: data.id }})"
            />
          </div>
        </div>
      </template>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
  },
  mixins: [mixinDate, mixinList],
  props: {
    surveys: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
